import type { ActivableProductInformationId, ActivableProductInformationIdTag, DimensionTag } from '@orus.eu/dimensions'
import { Tooltip } from '@orus.eu/pharaoh'
import type { Product } from '@orus.eu/product'
import { memo } from 'react'
import { Badge, type BadgeProps } from '../../../badge/badge.js'

const CommonProductBadge = memo<BadgeLabelAndVariantProps>(function CommonProductBadge({ props, label, tooltip }) {
  return (
    <Tooltip title={tooltip}>
      <Badge {...props}>{label}</Badge>
    </Tooltip>
  )
})

type ProductBadgeProps = {
  /**
   * The product to display the badge for.
   * When not provided, an empty fragment is rendered, as syntactic sugar to avoid
   * conditionally rendering the badge in many places.
   */
  product?: Product
}

export const ProductBadge = memo<ProductBadgeProps>(function ProductBadge({ product }) {
  return product ? (
    <CommonProductBadge
      props={badgePropsPerProduct[product].props}
      label={badgePropsPerProduct[product].label}
      tooltip={badgePropsPerProduct[product].tooltip}
    />
  ) : (
    <></>
  )
})

type ActivableProductInformationIdBadgeProps = {
  /**
   * The tag to display the badge for.
   * When not provided, an empty fragment is rendered, as syntactic sugar to avoid
   * conditionally rendering the badge in many places.
   */
  activableProductInformationId?: ActivableProductInformationId
}

export const ActivableProductInformationIdBadge = memo<ActivableProductInformationIdBadgeProps>(function ProductBadge({
  activableProductInformationId,
}) {
  return activableProductInformationId ? (
    <CommonProductBadge
      props={badgePropsPerActivableProductInformationId[activableProductInformationId].props}
      label={badgePropsPerActivableProductInformationId[activableProductInformationId].label}
      tooltip={badgePropsPerActivableProductInformationId[activableProductInformationId].tooltip}
    />
  ) : (
    <></>
  )
})

type Tag = DimensionTag | ActivableProductInformationIdTag

type TagBadgeProps = {
  /**
   * The tag to display the badge for.
   * When not provided, an empty fragment is rendered, as syntactic sugar to avoid
   * conditionally rendering the badge in many places.
   */
  tag?: Tag
}

export const TagBadge = memo<TagBadgeProps>(function TagBadge({ tag }) {
  return tag ? <Badge {...tagPropsPerTag[tag].props}>{tagPropsPerTag[tag].label}</Badge> : <></>
})

type BadgeLabelAndVariantProps = {
  props: { variant: BadgeProps['variant']; dark?: BadgeProps['dark'] }
  label: string
  tooltip?: string
}

const tagPropsPerTag: Record<Tag, BadgeLabelAndVariantProps> = {
  MRPH: { label: 'MRP', props: { variant: 'jasmine' }, tooltip: 'Multirisque local/bureau' },
  MRPW: { label: 'MRP', props: { variant: 'jasmine' }, tooltip: 'Multirisque professionnelle' },
  RCPH: { label: 'RCP', props: { variant: 'sky' }, tooltip: 'RC Professionnelle' },
  MUTA: { label: 'SANTÉ', props: { variant: 'peach' }, tooltip: 'Mutuelle santé TNS' },
  RCDA: { label: 'RCD', props: { variant: 'periwinkle' }, tooltip: 'RC Décennale' },
  CYBER: { label: 'CYBER', props: { variant: 'mindaro' }, tooltip: 'Couverture cyber' },
  TDMI: {
    label: 'TDMI',
    props: { variant: 'periwinkle' },
    tooltip: 'Tout dommage matériel informatique et professionnel',
  },
  'ES-RCPH': { label: '🇪🇸 RCP', props: { variant: 'sky' }, tooltip: 'Responsabilidad civil profesional' },
}

const badgePropsPerProduct: Record<Product, BadgeLabelAndVariantProps> = {
  mrph: tagPropsPerTag.MRPH,
  mrpw: tagPropsPerTag.MRPW,
  rcph: tagPropsPerTag.RCPH,
  muta: tagPropsPerTag.MUTA,
  rcda: tagPropsPerTag.RCDA,
  'es-rcph': tagPropsPerTag['ES-RCPH'],
}

const badgePropsPerActivableProductInformationId: Record<ActivableProductInformationId, BadgeLabelAndVariantProps> = {
  ...badgePropsPerProduct,
  'rc-pro': tagPropsPerTag.RCPH,
  'rcph-cyber': tagPropsPerTag.CYBER,
  'rcph-tdmi': tagPropsPerTag.TDMI,
  'es-rcph-pro': tagPropsPerTag['ES-RCPH'],
  'es-rcph-general': tagPropsPerTag['ES-RCPH'],
}
